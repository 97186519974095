import { formatData, tableItems } from './../service/table-data';
import { defineStore } from "pinia";
import { Ref, ref } from "vue";
import { TableItemProps, LinkedTaskProps } from "@/service/table-data";
import { isDateLessThanOrEqual, isDateGreaterThanOrEqual, isDateLessThan } from "@/utils/date";
import { title } from 'process';

// this is our 'plano de ação' app store
interface AppStoreProps {
  dataItems: TableItemProps[];
  filteredDataItems: TableItemProps[];
  initialTableCols: Ref<object[]>;
  favoriteItems: TableItemProps[];
  searchInput: Ref<string>;
  sidebarFilterKey: Ref<string>;
  isShowSidebar: Ref<boolean>;
  currentSelectedPlan: Ref<TableItemProps | null>;
  selectedTaskRow: Ref<LinkedTaskProps | null>;
  filterState: Ref<any>;
}

export const useAppStore = defineStore("appStore", {
  state: (): AppStoreProps => ({
    dataItems: [],
    filteredDataItems: [],
    initialTableCols: ref([]),
    favoriteItems: [],
    searchInput: ref(""),
    sidebarFilterKey: ref(""),
    isShowSidebar: ref(true),
    currentSelectedPlan: ref(null),
    selectedTaskRow: ref(null),
    filterState: ref(null),
  }),
  actions: {
    setInitialTableItems(payload: any) {
      const dataFormatted = formatData(payload)
      this.filteredDataItems = dataFormatted;
      this.dataItems = dataFormatted;
    },
    setTableFilter(payload: string, key?: "status" | null) {
      if (payload == "all") {
        return (this.filteredDataItems = this.dataItems);
      }
      if (key === "status") {
        return (this.filteredDataItems = this.dataItems.filter(
          (item) => item.status.label === payload
        ));
      }

      const filteredItems = () => {
        if (payload == "delayed") {
          const today = (new Date()).toLocaleDateString("pt-BR", { timeZone: "UTC" });

          return this.dataItems.filter((item) => {
            return isDateLessThan(item.deadline, today);
          });
        }
        if (payload == "in progress") {
          const statusValues = ["Plano criado", "Em andamento", "Finalizado"];

          return this.dataItems.filter((item) => {
            return statusValues.some((status) => item.status.label.includes(status));
          });
        }
        if (payload == "finished") {
          const statusValues = [
            "Plano encerrado",
            "Plano reprovado",
            "Plano aprovado",
          ];

          return this.dataItems.filter((item) => {
            return statusValues.some((status) => item.status.label.includes(status));
          });
        }
        if (payload == "favorite") {
          return this.favoriteItems;
        }
      };

      this.filteredDataItems = filteredItems();
    
    },
    setTableCustomFilter(payload: any) {
      this.filterState = payload;
      const filteredData = this.dataItems.filter(
        (item) =>
          (!payload?.type?.value ? true : item.plan_type === payload.type.value) &&
          (!payload?.priority?.value?.priority ? true : item.priority === payload.priority.value.priority) &&
          (!payload?.responsible?.value?.user_owner?.displayName ? true : item.responsible === payload.responsible.value.user_owner.displayName) &&
          (!payload?.evaluator?.value?.user_evaluator?.displayName ? true : item.evaluator === payload.evaluator.value.user_evaluator.displayName) &&
          (!payload?.startDate ? true : isDateGreaterThanOrEqual(item.deadline, payload.startDate)) &&
          (!payload?.endDate ? true : isDateLessThanOrEqual(item.deadline, payload.endDate))
      );
      this.filteredDataItems = filteredData;
    },
    resetCustomFilter(payload: string){
      this.filterState = null;
      this.filteredDataItems = this.dataItems;
    },
    setInitialTableCols(payload: any) {
      this.initialTableCols = payload;
    },
    addRowToFavorite(payload: any) {
      this.favoriteItems = [...this.favoriteItems, payload];
    },
    removeRowFromFavorite(payload: any) {
      const index = this.favoriteItems.findIndex(
        (item) => item.COD == payload.COD
      );

      this.favoriteItems = [
        ...this.favoriteItems.slice(0, index),
        ...this.favoriteItems.slice(index + 1),
      ];
    },
    setSearchInput(payload: string) {
      this.searchInput = payload;
    },
    setSidebarFilterKey(payload: string) {
      this.sidebarFilterKey = payload;
    },
    toggleSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },
    setCurrentSelectedPlan(payload: TableItemProps | null) {
      if (this.dataItems.length === 0) return;
      this.currentSelectedPlan = payload;
    },
    setSelectedTaskRow(payload: LinkedTaskProps | null) {
      this.selectedTaskRow = payload;
    },
  },
});
function formatStatus(status: any) {
  throw new Error('Function not implemented.');
}

