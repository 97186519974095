function parseDateBR(date: string): Date {
    const [day, month, year] = date.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day)); // month is 0-indexed
}

export function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function isDateLessThan(date1: string, date2: string): boolean {
    return parseDateBR(date1) < parseDateBR(date2);
}

export function isDateGreaterThan(date1: string, date2: string): boolean {
    return parseDateBR(date1) > parseDateBR(date2);
}

export function isDateEqual(date1: string, date2: string): boolean {
    return parseDateBR(date1).getTime() === parseDateBR(date2).getTime();
}

export function isDateLessThanOrEqual(date1: string, date2: string): boolean {
    return isDateLessThan(date1, date2) || isDateEqual(date1, date2);
}

export function isDateGreaterThanOrEqual(date1: string, date2: string): boolean {
    return isDateGreaterThan(date1, date2) || isDateEqual(date1, date2);
}