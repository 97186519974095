import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient, nhost, module, updateApolloClientByRole } from "@/main";
import { useQuery } from "@vue/apollo-composable";
import { gql } from "graphql-tag";

let role = "";
let last_role = "";
const updateModuleRoles = async (apC, id_user) => {
  if (!id_user) return;
  const { result, loading } = await provideApolloClient(apC)(() =>
    useQuery(
      gql`
        query getUserModuleRoles($id_user: uuid!) {
          user_module_role(where: { id_user: { _eq: $id_user } }) {
            role
            module {
              module
            }
          }
        }
      `,
      {
        id_user,
      },
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  while (loading.value) {
    await new Promise((r) => setTimeout(r, 100));
  }
  if (result?.value?.user_module_role) {
    localStorage.module_role = JSON.stringify(result.value.user_module_role);
  }
};

const getRoleByModule = async (apc, id_user) => {
  localStorage.removeItem("module_role");
  await updateModuleRoles(apc, id_user);
  if (!localStorage.module_role) return "user";
  return JSON.parse(localStorage.module_role).find((rm) => {
    return rm?.module?.module == module;
  })?.role;
};

export const updateRole = async (
  apc = apolloClient,
  id_user = nhost.auth.getUser()?.id
) => {
  role = await getRoleByModule(apc, id_user);
  if (!role || role == "user") role = last_role;
  if (role != last_role) {
    await updateApolloClientByRole(role);
    last_role = role;
  }
  return role;
};

export { role };
